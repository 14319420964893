@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: 200px;
  height: 200px;
  cursor: $cursor-pointer;
  background-color: $black;
  position: relative;
}

.card {
  padding: 24px;
  width: 200px;
  height: 200px;
  border: 2px solid $black;
  @include pointer;
  display: block;
  background-color: $white;
  transition: all 0.1s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  text-decoration: none;
  color: $black;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;

  @include vp-495 {
    margin-bottom: 36px;
  }
}

.title {
  font-size: 24px;
  user-select: none;
}

.hovered {
  transform: translate(10px, -10px);
  z-index: 1;
}

.desc {
  transform: translateY(150%);
  overflow: hidden;
  transition: all 0.3s;
}

.active {
  transform: translateY(0%);
}
