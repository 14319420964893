@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: 910px;
  position: relative;
  height: 560px;

  @include vp-1023 {
    width: 100%;
  }

  @include vp-840 {
    height: 460px;
  }

  &:hover {
    .title {
      background-color: $gray-dark;
    }

    .img {
      filter: contrast(90%) grayscale(0%);
    }
  }
}

.item {
  height: 100%;
  border: 2px solid $black;
  display: flex;
  flex-direction: column;
  @include pointer;
  transition: all 0.3s;
}

.title {
  padding: 16px;
  background-color: $black;
  color: $white;
  text-align: end;
  transition: all 0.3s;
  height: 52px;
  min-height: 52px;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  filter: contrast(90%) grayscale(100%);
  transition: all 0.3s;
  object-position: -70px 0px;
}

.mobile {
  object-position: 0px 0px;
}

.slider {
  display: flex !important;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.buttons {
  display: flex;
  gap: 4px;
  height: 54px;
  width: 108px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

button.button {
  min-width: 54px;
  width: 54px;
  padding: 12px;
}

div.circle {
  left: 60px;
  bottom: 40px;

  @include vp-840 {
    bottom: 80px;
    left: 40px;
  }
}

.arrow {
  fill: $black;
}

.arrowLeft {
  transform: rotate(180deg);
  fill: $black;
}
