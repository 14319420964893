@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  position: relative;
  width: 300px;
  height: 360px;
  animation: fly 5s ease-in-out infinite;
  @include pointer;

  @include vp-840 {
    width: 150px;
    height: 180px;
  }
}

.like {
  width: 180px;
  height: 180px;
  position: absolute;
  top: 40px;
  left: -40px;
  animation: move 5s ease-in-out infinite;

  @include vp-840 {
    width: 100px;
    height: 100px;
    top: 10px;
    left: -20px;
  }
}

.ghost {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
}

.hand {
  width: 160px;
  height: 160px;
  position: absolute;
  top: 110px;
  right: -80px;
  transform: rotate(20deg);

  @include vp-840 {
    width: 100px;
    height: 100px;
    top: 40px;
    right: -50px;
  }
}

.clicked {
  animation: touch 2s ease-in-out;
}

@keyframes touch {
  0% {
    transform: translateY(0%) rotate(0deg);
  }
  15% {
    transform: translateY(-30%) rotate(10deg);
  }
  30% {
    transform: translateY(30%) rotate(-10deg);
  }
  45% {
    transform: translateY(-20%) rotate(5deg);
  }
  60% {
    transform: translateY(20%) rotate(-5deg);
  }
  75% {
    transform: translateY(-10%) rotate(2deg);
  }
  90% {
    transform: translateY(10%) rotate(-2deg);
  }
  100% {
    transform: translateY(0%) rotate(0deg);
  }
}

@keyframes fly {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(10%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes move {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
