@import 'src/styles/variables';
@import 'src/styles/mixins';

.main {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 64px 0;

  @include vp-840 {
    padding: 0;
  }
}

div.marquee {
  width: 100%;
  @include spacing;
}
