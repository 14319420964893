@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  align-items: center;
  background-color: $white;
  padding: 16px 0;
  border-top: 2px solid $black;
  border-bottom: 2px solid $black;
  animation: show 0.9s ease-in-out;
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.text {
  font-size: 18px;
  margin-right: 16px;
}

.img {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 16px;
}
