@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  padding: 4px 8px;
  color: $black;
  border: 2px solid $gray-dark;
  width: fit-content;
}

.secondary {
  color: $gray-dark;
  border: 2px solid $gray-dark;
}
