@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  @include page;
  overflow: hidden;
  height: 540px;
  position: relative;

  @include vp-840 {
    height: 400px;
  }
}

.names {
  display: flex;
  gap: 36px;

  @include vp-840 {
    flex-direction: column;
    align-items: center;
    padding-right: 20px;
    gap: 0;
  }
}

div.surname {
  @include vp-840 {
    display: none;
  }
}

.desc {
  width: 100%;

  @include vp-840 {
    text-align: center;
  }
}

.content {
  display: flex;
  flex-direction: column;
}

.ghost {
  position: absolute;
  right: 100px;
  bottom: 50px;

  @include vp-1023 {
    right: 100px;
  }

  @include vp-840 {
    position: static;
    right: auto;
    left: auto;
    bottom: auto;
    top: auto;
    display: flex;
    justify-content: center;
  }
}
