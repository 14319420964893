@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  @include page;
  @include spacing;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include vp-1368 {
    padding: 0 24px;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @include vp-840 {
    margin-bottom: 150px;
  }
}

.title {
  @include spacing-sm;
}

.button {
  &Wrapper {
    height: 90px;
    width: 190px;

    @include vp-840 {
      height: 60px;
    }
  }
}

.arrow {
  fill: $black;
}

.frame {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include vp-1023 {
    width: 100%;
    align-items: flex-start;
  }
}

.info {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.img {
  position: absolute;
  top: 100px;
  left: -200px;
  width: 480px;
  height: 240px;
  z-index: -1;
  animation: fly 7s ease-in-out infinite;

  @include vp-840 {
    left: -100px;
    width: 240px;
    height: 120px;
    bottom: 50px;
    top: auto;
    animation: flyMob 7s ease-in-out infinite;
  }
}

@keyframes fly {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-15%) rotate(-5deg);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes flyMob {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(10%) rotate(5deg);
  }
  100% {
    transform: translateY(0%);
  }
}

.empty {
  height: 200px;

  @include vp-840 {
    height: 100px;
  }
}
