@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  font-size: 120px;
  display: flex;
  gap: 3px;
  width: fit-content;

  @include vp-1023 {
    font-size: 90px;
  }

  @include vp-840 {
    font-size: 72px;
  }
}

.char {
  cursor: $cursor-pointer;
  transition: all 0.3s;
  position: relative;
  font-weight: 900;
  user-select: none;
}

.bottom {
  transition: all 0.3s ease-in-out;
  color: $black;
  font-weight: 900;
  user-select: none;
  text-shadow: -1px 1px $black, -1px -1px $black, 1px 1px $black;
}

.moving {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  color: $black;
  font-weight: 900;
  user-select: none;
  text-shadow: -1px 1px $black, -1px -1px $black, 4px 2px $black;
}

.top {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  color: $white;
  text-shadow: -2px -2px $black, 2px -2px $black, 2px 2px $black,
    -2px 2px $black;
  font-weight: 900;
  user-select: none;
}
